import * as React from "react";
import { Button, Nav, Navbar, Row, Badge, Container } from "react-bootstrap";
import { FontAwesomeIcon } from "../components/fontawesome";
import * as fa from "@fortawesome/free-solid-svg-icons";
import { Helmet } from "react-helmet";
import { Link } from "gatsby";
import Seo from "../components/seo"
import BottomNav from "../components/bottomnav";
import Layout from "../components/layout";
import timmy from "../images/PinClipart.com_clipart-clipart_74572.png";

const Layanan = () => (
    <Row className="m-0 my-4">
        <button className="btn col-4 col-lg-2 d-flex flex-column text-center">
            <span className="fa-layers fa-3x w-100">
                <FontAwesomeIcon
                    icon={fa.faSquare}
                    style={{ color: "#eaeaef" }}
                />
                <FontAwesomeIcon
                    icon={fa.faCoins}
                    style={{ color: "#333366" }}
                    transform="shrink-9"
                />
            </span>
            Infaq
        </button>
        <button className="btn col-4 col-lg-2 d-flex flex-column text-center">
            <span className="fa-layers fa-3x w-100">
                <FontAwesomeIcon
                    icon={fa.faSquare}
                    style={{ color: "#eaeaef" }}
                />
                <img
                    src={timmy}
                    className="position-absolute"
                    style={{
                        transform: "translateX(-50%)",
                        height: "70%",
                        top: "15%",
                    }}
                />
            </span>
            Qurban
        </button>
        <button className="btn col-4 col-lg-2 d-flex flex-column text-center">
            <span className="fa-layers fa-3x w-100">
                <FontAwesomeIcon
                    icon={fa.faSquare}
                    style={{ color: "#eaeaef" }}
                />
                <FontAwesomeIcon
                    icon={fa.faWallet}
                    style={{ color: "#333366" }}
                    transform="shrink-9"
                />
            </span>
            Donasi
        </button>
        <button className="btn col-4 col-lg-2 d-flex flex-column text-center">
            <span className="fa-layers fa-3x w-100">
                <FontAwesomeIcon
                    icon={fa.faSquare}
                    style={{ color: "#eaeaef" }}
                />
                <FontAwesomeIcon
                    icon={fa.faFileContract}
                    style={{ color: "#333366" }}
                    transform="shrink-9"
                />
            </span>
            Mudhorobah
        </button>
        <button className="btn col-4 col-lg-2 d-flex flex-column text-center">
            <span className="fa-layers fa-3x w-100">
                <FontAwesomeIcon
                    icon={fa.faSquare}
                    style={{ color: "#eaeaef" }}
                />
                <FontAwesomeIcon
                    icon={fa.faHandshake}
                    style={{ color: "#333366" }}
                    transform="shrink-9"
                />
            </span>
            Musyarakah
        </button>
        <button className="btn col-4 col-lg-2 d-flex flex-column text-center">
            <span className="fa-layers fa-3x w-100">
                <FontAwesomeIcon
                    icon={fa.faSquare}
                    style={{ color: "#eaeaef" }}
                />
                <FontAwesomeIcon
                    icon={fa.faEllipsisH}
                    style={{ color: "#333366" }}
                    transform="shrink-9"
                />
            </span>
            Lainnya
        </button>
    </Row>
);

const adaNotif = true;

const IndexPage = () => {
    return (
        <Layout>
            <Seo title="Beranda"/>
            <Navbar variant="light" bg="white" className="border-bottom">
                <Navbar.Brand
                    className="font-weight-bold"
                    style={{ color: "#333366" }}
                >
                    Islah 24
                </Navbar.Brand>
                <Nav className="ml-auto">
                    <Nav.Item>
                        <button className="btn">
                            <span className="fa-layers fa-fw">
                                {adaNotif ? (
                                    <>
                                        <FontAwesomeIcon
                                            icon={fa.faCircle}
                                            transform="shrink-2 right-5 up-5"
                                            className="text-white"
                                        />
                                        <FontAwesomeIcon
                                            icon={fa.faCircle}
                                            transform="shrink-2 right-5 up-5"
                                            mask={fa.faBell}
                                        />
                                        <FontAwesomeIcon
                                            icon={fa.faCircle}
                                            transform="shrink-4 right-5 up-5"
                                            className="text-danger"
                                        />
                                    </>
                                ) : (
                                    <FontAwesomeIcon icon={fa.faBell} />
                                )}
                            </span>
                        </button>
                    </Nav.Item>
                </Nav>
            </Navbar>
            <main className="my-3">
                <section id="layanan" className="bg-white my-3 py-3">
                    <Container>
                        <Layanan />
                    </Container>
                </section>
                <section id="tentang" className="bg-white my-3 py-3">
                    <Container>
                        <Link to="/tentang-kami">
                            <div className="font-weight-bold">
                                Semua tentang Islah 24
                                <span className="fa-layers fa-fw mx-1">
                                    <FontAwesomeIcon
                                        icon={fa.faCircle}
                                        style={{ color: "#eaeaef" }}
                                    />
                                    <FontAwesomeIcon
                                        icon={fa.faArrowRight}
                                        transform="shrink-6"
                                    />
                                </span>
                            </div>
                        </Link>
                        <p>
                            Paragraf singkat tentang Islah 24. Totam ut enim vel
                            error veniam ratione ipsum. Non labore cum id quas.
                            Earum error eveniet ipsam rerum quis at asperiores.
                            In vel quam commodi.
                        </p>
                    </Container>
                </section>
                <div className="my-3 py-3 container text-center text-muted">
                    <small>Hanya itu untuk sekarang</small>
                </div>
            </main>
            <BottomNav />
        </Layout>
    );
};

export default IndexPage;
