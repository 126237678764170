import * as React from "react";
import { navigate } from "gatsby";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as fa from "@fortawesome/free-solid-svg-icons";

const TopNav = ({ title, children, className }) => {
    return (
        <nav className={"border-bottom bg-white " + className}>
            <div className="container-fluid d-flex align-items-center py-2">
                <button onClick={() => navigate(-1)} className="btn mr-3">
                    <FontAwesomeIcon
                        icon={fa.faArrowLeft}
                        className="text-primary"
                    />
                </button>
                <span className="py-1 font-weight-bold">{title}</span>
            </div>
            {children}
        </nav>
    );
};

export default TopNav;
