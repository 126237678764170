import * as React from "react";
import TopNav from "./topnav";
import BottomNav from "./bottomnav";
import { Helmet } from "react-helmet";

import "../styles/index.scss";
import "@fontsource/roboto/index.css";
import "@fontsource/roboto/700.css";

const Layout = ({
    children,
    title,
    topNav,
    bottomNav,
    bg,
}: {
    children?;
    title?;
    topNav?;
    bottomNav?;
    bg?;
}) => {
    return (
        <>
            <Helmet>
                <body className={bg && "bg-" + bg} />
            </Helmet>
            {topNav && <TopNav title={title} className="mb-3">{topNav}</TopNav>}
            {children}
            {bottomNav && <BottomNav />}
        </>
    );
};

export default Layout;
